import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Em, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				PrimeFit Arena
			</title>
			<meta name={"description"} content={"From high-intensity interval training (HIIT) to calming yoga classes, each visit offers a new opportunity to challenge yourself and achieve your goals."} />
			<meta property={"og:title"} content={"PrimeFit Arena"} />
			<meta property={"og:description"} content={"From high-intensity interval training (HIIT) to calming yoga classes, each visit offers a new opportunity to challenge yourself and achieve your goals."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="quarklycommunityKitMenuOverride5">
				Services
			</Override>
			<Override slot="quarklycommunityKitMenuOverride6" />
			<Override slot="quarklycommunityKitMenuOverride7" />
			<Override slot="link" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
		</Components.Header>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				lg-display="flex"
				lg-justify-content="center"
				padding="0px 16px 0px 0px"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(9, 60px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px"
					max-width="480px"
					lg-margin="0px 0px 16px 0px"
					sm-grid-template-rows="repeat(9, 10vw)"
				>
					<Image
						src="https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-row="1 / span 5"
						grid-column="1 / span 1"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-4.jpg?v=2024-02-14T12:13:02.771Z"
						border-radius="24px"
						object-fit="cover"
						grid-column="2 / span 1"
						grid-row="2 / span 3"
						width="100%"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-4.jpg?v=2024-02-14T12:13:02.761Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="1 / span 1"
						grid-row="6 / span 3"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-4.jpg?v=2024-02-14T12%3A13%3A02.761Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-4.jpg?v=2024-02-14T12%3A13%3A02.761Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-4.jpg?v=2024-02-14T12%3A13%3A02.761Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-4.jpg?v=2024-02-14T12%3A13%3A02.761Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-4.jpg?v=2024-02-14T12%3A13%3A02.761Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-4.jpg?v=2024-02-14T12%3A13%3A02.761Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-4.jpg?v=2024-02-14T12%3A13%3A02.761Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-3.jpg?v=2024-02-14T12:13:02.762Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="2 / span 1"
						grid-row="5 / span 5"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-3.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-3.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-3.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-3.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-3.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-3.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-3.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="20px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				lg-width="100%"
				lg-padding="24px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Welcome to PrimeFit Arena
				</Text>
				<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					At PrimeFit Arena, fitness goes beyond the ordinary. It’s not just about lifting weights or running miles—it’s about creating a lifestyle that energizes, empowers, and excites you. We aim to inspire you on your fitness journey by offering innovative workouts, supporting community, and creating an atmosphere that motivates you to go beyond your limits.
				</Text>
			</Box>
		</Section>
		<Section padding="120px 0 140px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-4">
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="50px 0px 0px 0px"
				md-flex-direction="column"
				sm-margin="30px 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					margin="0px 10% 0px 0px"
					padding="4px 0px 0px 0px"
					md-width="100%"
					md-margin="0px 10% 50px 0px"
					sm-padding="0 0px 0px 0px"
					sm-margin="0px 10% 35px 0px"
				>
					<Text margin="0px 0px 2rem 0px" font="normal 700 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						Transform Your Fitness at PrimeFit Arena
					</Text>
					<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						PrimeFit Arena is where motivation meets innovation. Our facility features a wide range of equipment and classes designed for all fitness levels and interests. From high-intensity interval training (HIIT) to soothing yoga sessions, every visit offers a new opportunity to challenge yourself and reach your goals.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						13+
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						years of experience
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						30+
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						professional trainers
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						100+
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						pieces of equipment
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					A Variety of Workouts
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Our certified trainers help you create a personalized workout plan to get the most out of your time at PrimeFit.
					<br />
					<br />
					<Em>
						Our certified trainers help you create a personalized workout plan to get the most out of your time at PrimeFit.
					</Em>
				</Text>
				<Link
					href="/services"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Services
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-3.jpg?v=2024-02-14T12:13:02.768Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-3.jpg?v=2024-02-14T12%3A13%3A02.768Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-3.jpg?v=2024-02-14T12%3A13%3A02.768Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-3.jpg?v=2024-02-14T12%3A13%3A02.768Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-3.jpg?v=2024-02-14T12%3A13%3A02.768Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-3.jpg?v=2024-02-14T12%3A13%3A02.768Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-3.jpg?v=2024-02-14T12%3A13%3A02.768Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-3.jpg?v=2024-02-14T12%3A13%3A02.768Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-2.jpg?v=2024-02-14T12:13:02.780Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-2.jpg?v=2024-02-14T12%3A13%3A02.780Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-2.jpg?v=2024-02-14T12%3A13%3A02.780Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-2.jpg?v=2024-02-14T12%3A13%3A02.780Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-2.jpg?v=2024-02-14T12%3A13%3A02.780Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-2.jpg?v=2024-02-14T12%3A13%3A02.780Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-2.jpg?v=2024-02-14T12%3A13%3A02.780Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-2.jpg?v=2024-02-14T12%3A13%3A02.780Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Refresh and Recharge
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Enjoy our relaxation areas equipped with amenities to help you cool down and recover after a workout, making every visit as rewarding as it is challenging.
					<br />
					<br />
					<Em>
						Experience the power of workouts within a community that supports you. At PrimeFit, it’s about mutual encouragement and shared victories.
					</Em>
				</Text>
				<Link
					href="/services"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Services
				</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link" />
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="link1" />
			<Override slot="text2" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65c4dc500072400020e9c714"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});